import React, { useRef, useState } from "react";
import { Box } from "@mui/material";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import "./style.css"

const VideoCard: React.FC = () => {
  const [playVideo, setPlayVideo] = useState(false);

  const handlePlayVideo = () => {
    setPlayVideo(true);
  };

  const videoId = 'jpsJ4GFt7S4'; // Replace with your YouTube video ID
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`; // High-quality thumbnail

  return (
    <Box
  sx={{
    width: '88vw',
    height: {
      md: '80vh',
      xl:"110vh",
      lg:"80vh",
      xs: "30vh",
      sm:"50vh"

    },
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor:"black",
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: '16px',
    cursor: playVideo ? 'default' : 'pointer',
  }}
  onClick={!playVideo ? handlePlayVideo : undefined} // Only clickable before playing
>
  {playVideo ? (
    <iframe
      width="100%"
      height="100%"
      src={`https://www.youtube.com/embed/${videoId}?rel=0&autoplay=1&controls=0`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      style={{
        position: 'absolute',  // Ensure iframe fills the container
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }}
    ></iframe>
  ) : (
    <>
      <img
        src={thumbnailUrl}
        alt="YouTube Video Thumbnail"
        style={{
          position: 'absolute', // Ensure the image fills the container
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'fill',  // Use 'cover' to fill the container without stretching
          borderRadius: '16px',
        }}
      />
      {/* Play button */}
      <Box
        sx={{
          position: 'absolute',
          width: '80px',
          height: '50px',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          padding: '10px 20px',
          borderRadius: '10px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          fontSize: '30px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)', // Shadow for visibility
          zIndex: 2,
        }}
      >
        <PlayArrowIcon />
      </Box>
    </>
  )}
</Box>

  );
};

export default VideoCard;

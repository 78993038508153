import { Box, Typography } from "@mui/material";
import VideoCard from "../VideoCard/VideoCard";
import leftball from "../../assets/images/leftball.svg";
import rightball from "../../assets/images/rightball.svg";
import useMobile from "../../customHook/useMobile";




const VideoSection: React.FC = () => {

  const isMobile = useMobile()
  return (
    <Box sx={{
      position: 'relative',
    }}>
     {
      !isMobile && <>
       <img src={leftball} alt="" style={{ position: "absolute", top: "170px", left: "0", height: "250px", width: "250px" }} />
       <img src={rightball} alt=""  style={{ position: "absolute", bottom: "30px", right: "0", height: "250px", width: "250px" }} />
      </>
     }
      <Box
        sx={{
          paddingLeft: "6%",
          paddingRight: "6%",
          paddingBottom:{
            xs:"12%",
            md:"5%"
          },
          // background:"red"
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: "#000",
            fontFamily: "Poppins !important",
            fontSize: { xs: "24px", md: "34px" },
            fontStyle: "normal",
            fontWeight:600,
            padding:{
              xs:"12% 0 8% 0",
              md:"5% 0 3% 0"
            }
          }}
        >
          Here's how we do it!
        </Typography>
        <VideoCard />
      </Box>
    </Box>
  );
};

export default VideoSection;
